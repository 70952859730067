.main{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.card {
    position: relative;
    display: flex;
    min-height: 300px;
    background: linear-gradient(43deg, #4158D0 0%, #c850c0 46%, #ffcc70 100%);
}
.card::before,
.card::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
}
.card::before{
    border-style: solid;
    border-width: 0 0 0px 0px;
    border-color: transparent transparent #fff transparent;
    transition: border .25;
}
.card::after {
    content: "GO";
    bottom: 15px;
    color: #151515;
    font-size: 21px;
    opacity: 0;
}
.card:hover::before {
    border-width: 0 0 100px 100px;
}
.card:hover::after {
    opacity: 1;
    transition: opacity .20s;
}
.card__title {
    margin: auto;
    font-size: 56px;
}
.text-dc-none{
    color: #151515;
    text-decoration: none !important;
    font-style: normal ;
    font-stretch: extra-expanded;
}